import axios from 'axios';
import Store from '@/store/index';
import { alert } from '@/utility/messages';

const userKey = 'user:hoikm';
const permanentKey = `${userKey} permanent` as const;
let lastUpdated = 0;

export interface LoginUser {
  id: string;
  password: string;
  save: boolean;
}
export interface AuthInfo {
  staffCode: number;
  staffName: string;
  staffMainBaseCode: string;
  mbjType: string;
  staffSubBaseCode: string;
  sbjType: string;
  headquarters: boolean;
  sRoll: string;
  browsingAuthority: BrowsingAuthority;
  token: string;
}
export interface BrowsingAuthority {
  /** 動静 */
  motionAndStatic: number;
  /** 従事者予定 */
  scheduleOfWorkers: number;
  /** 従事者実績 */
  employeePerformance: number;
  /** 児童予定・児童実績 */
  childrenScheduleAchieveme: number;
  /** CSV取り込み */
  csvImport: number;
  /** 従事者マスタ */
  employeeMaster: number;
  /** 児童マスタ */
  childMaster: number;
  baseMaster: number;
  /** 行事マスタ */
  eventMaster: number;
  /** 竹出力 */
  bambooOutput: number;
  /** メール送信 */
  sendEmail: number;
  /** スマホ */
  smartphoneSite: number;
  /** タブレット */
  tabletSite: number;
  /** 報連相マスタ */
  hourensoMaster: number;
  /** 管理帳票 */
  managementForm: number;
  /**	受託請求書 */
  consignmentBill: number;
  /**	鑑定士 */
  appraiser: number;
  /**	支給管理 */
  paymentManagement: number;
  /**	締切日設定 */
  deadlineSetting: number; //< 未使用
  /** 有給登録 */
  spaidLeaveRegistration: number;
}

export function initUser(): LoginUser {
  return <LoginUser>{
    id: '',
    password: '',
    save: isPermanent(),
  };
}
export function isPermanent() {
  return localStorage.getItem(permanentKey) === 'true';
}
export function getStorage() {
  return isPermanent() ? localStorage : sessionStorage;
}
export function getLoginUser() {
  const json = getStorage().getItem(userKey);
  if (json === null) return null;
  const loginUser = JSON.parse(json) as AuthInfo;
  return loginUser;
}
export function setLoginUser(loginUser: LoginUser, permanent: boolean) {
  lastUpdated = Date.now();
  localStorage.setItem(permanentKey, JSON.stringify(permanent));
  getStorage().setItem(userKey, JSON.stringify(loginUser));
}
export async function updateLoginUser() {
  await new Promise(resolve => setTimeout(resolve));
  if (Date.now() - lastUpdated < 60 * 1000) return;
  lastUpdated = Date.now();
  const user = getLoginUser();
  if (!user) {
    return;
  }
  lastUpdated = Date.now();
  const config = {
    headers: { Authorization: 'Bearer ' + user.token },
  };
  const resonse = await axios.get<LoginUser>('/api/auth', config);
  setLoginUser(resonse.data, isPermanent());
}
export async function login(id: string, password: string, sender: string, save: boolean) {
  if (Number.isNaN(parseInt(id))) {
    alert('ID・パスワードが間違っているか、入力されていない可能性があります。ご確認下さい。');
    return;
  }
  const response = await axios.post('/api/auth/', { id: id, password: password, sender: sender });
  setLoginUser(response.data, save);
}
export function logout() {
  getStorage().removeItem(userKey);
}
